import { FunctionComponent, useEffect } from 'react';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import {
    SkeletonTextProps,
    skeletonTextStyleSheet,
    getSkeletonTextRootClasses,
    getSkeletonTextClasses,
    defaultSkeletonTextProps,
} from '@gs-ux-uitoolkit-common/skeleton';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { componentAnalytics } from './analytics-tracking';

/**
 * Skeleton text can be used to make a page 'appear' fast by adding placeholders for
 * typography elements that are still being loaded.
 */
export const SkeletonText: FunctionComponent<SkeletonTextProps> = (props: SkeletonTextProps) => {
    const {
        typography = defaultSkeletonTextProps.typography,
        width = defaultSkeletonTextProps.width,
        lines = defaultSkeletonTextProps.lines,
        animation = defaultSkeletonTextProps.animation,
        disableAriaLabel = defaultSkeletonTextProps.disableAriaLabel,
        className,
        classes: overrideClasses,
        'aria-label': ariaLabel = defaultSkeletonTextProps['aria-label'],
        ...otherProps
    } = props;
    const theme = useTheme();
    const cssClasses = useStyleSheet(skeletonTextStyleSheet, { ...props, theme });

    // Get the CSS Stylesheets for skeleton text elements
    const rootCx = getSkeletonTextRootClasses({ cssClasses, className, overrideClasses });
    const textCx = getSkeletonTextClasses({ cssClasses });

    // Create an empty array with the same number of items as 'lines' to be used
    // in the markup map() => ...
    const linesMap = Array((lines || 1) + 1)
        .join('.')
        .split('');

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({
            componentName: 'skeleton-text',
            officialComponentName: 'other',
        });
    }, []); // Only run once

    return (
        <div
            data-gs-uitk-component="skeleton-text"
            data-typography={typography}
            data-lines={lines}
            data-animation={animation}
            className={rootCx}
            aria-busy="true"
            aria-live="off"
            aria-label={disableAriaLabel ? undefined : ariaLabel}
            {...otherProps}
        >
            {linesMap.map((s, i) => (
                <div
                    key={i}
                    data-cy="gs-uitk-skeleton__text"
                    data-skeleton-text-line={`line-${s}${i}`}
                    className={textCx}
                    style={{ width: props.width }}
                ></div>
            ))}
        </div>
    );
};
