import { createContext } from 'react';
import { MenuContext as CommonMenuContext, defaultMenuContext } from '@gs-ux-uitoolkit-common/menu';

export interface ReactMenuContext extends CommonMenuContext {
    depth: number;
}

export const MenuContext = createContext<ReactMenuContext>({
    ...defaultMenuContext,

    // Used as a z-index value, this allows child menus to display visually
    // above parent menus (it is incremented for each child menu). Necessary for
    // React only because that renders the root HTML element to the DOM only
    // once, rendering children then parents so that otherwise parents will
    // display visually above their children. In Angular,
    // document.body.appendChild() can be called each time "visible" is set to
    // true so that setting z-index is unnecessary.
    depth: 9999,
});
