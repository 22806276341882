import { ChangeEvent, CSSProperties, HTMLAttributes, FC } from 'react';
import PropTypes from 'prop-types';
import {
    sliderMultipleDefaultProps,
    SliderMultipleProps as SliderMultipleCommonProps,
} from '@gs-ux-uitoolkit-common/slider';
import { SliderBase } from './slider-base';

export interface SliderMultipleChangeEvent extends ChangeEvent<HTMLInputElement> {
    values: number[];
    activeIndex: number;
}

export interface SliderMultipleProps
    extends SliderMultipleCommonProps<CSSProperties>,
        HTMLAttributes<HTMLElement> {
    /**
     * Event raised when either of the slider's values change
     */
    onChange?: (event: SliderMultipleChangeEvent) => void;
}

/**
 * Multi-Point Slider to visualize and allow for the selection of numeric values within a range.
 */
export const SliderMultiple: FC<SliderMultipleProps> = props => {
    const {
        disabled = sliderMultipleDefaultProps.disabled,
        max = sliderMultipleDefaultProps.max,
        min = sliderMultipleDefaultProps.min,
        showLabels = sliderMultipleDefaultProps.showLabels,
        showValues = sliderMultipleDefaultProps.showValues,
        size = sliderMultipleDefaultProps.size,
        step = sliderMultipleDefaultProps.step,
        ...otherProps
    } = props;

    return (
        <SliderBase
            disabled={disabled}
            max={max}
            min={min}
            showLabels={showLabels}
            showValues={showValues}
            size={size}
            step={step}
            {...otherProps}
            sliderType={'slider-multiple'}
        />
    );
};

SliderMultiple.propTypes = {
    max: PropTypes.number,
    min: PropTypes.number,
    step: PropTypes.number,
    values: PropTypes.arrayOf(PropTypes.number.isRequired),
    defaultValues: PropTypes.arrayOf(PropTypes.number.isRequired),
    onChange: PropTypes.func,
    showValues: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    showLabels: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    className: PropTypes.string,
    classes: PropTypes.object,
};
