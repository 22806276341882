import { FunctionComponent, useEffect } from 'react';
import { Icon } from '@gs-ux-uitoolkit-react/icon-font';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import {
    SkeletonAreaProps,
    skeletonAreaStyleSheet,
    getSkeletonAreaRootClasses,
    getSkeletonAreaIconClasses,
    defaultSkeletonAreaProps,
} from '@gs-ux-uitoolkit-common/skeleton';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { componentAnalytics } from './analytics-tracking';

/**
 * Skeleton area can be used to make a page 'appear' fast by adding placeholders over an area
 * of the page where elements like images, charts and graphs are loading.
 */
export const SkeletonArea: FunctionComponent<SkeletonAreaProps> = (props: SkeletonAreaProps) => {
    const {
        width = defaultSkeletonAreaProps.width,
        height = defaultSkeletonAreaProps.height,
        icon,
        animation = defaultSkeletonAreaProps.animation,
        className,
        classes: overrideClasses,
        'aria-label': ariaLabel = defaultSkeletonAreaProps['aria-label'],
        ...otherProps
    } = props;
    const theme = useTheme();
    const cssClasses = useStyleSheet(skeletonAreaStyleSheet, { theme, ...props });

    const areaCx = getSkeletonAreaRootClasses({ cssClasses, className, overrideClasses });
    const iconCx = getSkeletonAreaIconClasses({ cssClasses });

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({
            componentName: 'skeleton-area',
            officialComponentName: 'other',
        });
    }, []); // Only run once

    return (
        <div
            data-gs-uitk-component="skeleton-area"
            data-width={width}
            data-height={height}
            data-icon={icon}
            data-animation={animation}
            className={areaCx}
            aria-busy="true"
            aria-live="off"
            aria-label={ariaLabel}
            {...otherProps}
        >
            {icon && <Icon name={icon || 'image'} type="outlined" className={iconCx} />}
        </div>
    );
};
