import {
    FunctionComponent,
    useContext,
    useState,
    useEffect,
    KeyboardEvent,
    FocusEvent,
    MouseEvent,
} from 'react';
import PropTypes from 'prop-types';
import {
    defaultTagProps,
    tagStyleSheet,
    getTagRootClasses,
    getTagButtonClasses,
    TagClickEvent,
    TagDismissEvent,
} from '@gs-ux-uitoolkit-common/tag';
import { CloseButton, CloseButtonProps } from '@gs-ux-uitoolkit-react/close-button';
import { KeyHelpers, splitDataAttributes } from '@gs-ux-uitoolkit-react/shared';
import { cx, useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { TagProps, basePropTypes } from './tag-props';
import { componentAnalytics } from './analytics-tracking';
import { TagContext } from './tag-context';

/**
 * Tags can display selections, filter content, or trigger actions. Unlike Buttons which should
 * be displayed consistently with static calls to action, Tags should be displayed dynamically
 * to show the selected items in a multiple selection component.
 * Unlike Badges which provide a highlight or notification, Tags are interactive components that
 * allow users to display and update selections, filters, and similar data.
 */
export const Tag: FunctionComponent<TagProps> = props => {
    const {
        tagButtonAttrs,
        classes: overrideClasses,
        className,
        dismissButtonAttrs,
        disabled,
        dismissible,
        emphasis = defaultTagProps.emphasis,
        size = defaultTagProps.size,
        shape = defaultTagProps.shape,
        color = defaultTagProps.color,
        status,
        name,
        value,
        selected,
        id,
        children,
        style,
        tabIndex,
        title,
        onClick,
        onDismiss,
        onFocus,
        onBlur,
        onMouseOver,
        onMouseOut,
        onMouseDown,
        ...otherProps
    } = props;

    const [focussed, setFocussed] = useState(false);
    const [hover, setHover] = useState(false);
    const [active, setActive] = useState(false);

    const { isToggle } = useContext(TagContext);

    function createTagEvent(): TagClickEvent | TagDismissEvent {
        return {
            name,
            value,
            selected: selected || false,
        };
    }

    function handleClick() {
        if (onClick) {
            onClick(createTagEvent());
        }
    }

    function handleDismiss() {
        callOnDismiss();
    }

    function handleKeyDown(event: KeyboardEvent) {
        const { SPACE, ENTER, DELETE, BACKSPACE } = KeyHelpers.keyCode;
        if (event.which === SPACE || event.which === ENTER) {
            // Don't scroll the page (SPACE) or submit a form (ENTER):
            event.preventDefault();
            handleClick();
        } else if (dismissible && (event.which === DELETE || event.which === BACKSPACE)) {
            // Don't visit the previous page or do anything unexpected:
            event.preventDefault();
            callOnDismiss();
        }
    }

    function handleFocus(event: FocusEvent<HTMLButtonElement>) {
        if (!disabled) {
            if (!active) {
                // Ignore focus if "active" (mousedown) is true in order to
                // replicate "focus-visible" instead of just "focus".
                setFocussed(true);
            }
            if (onFocus) {
                onFocus(event);
            }
        }
    }

    function handleBlur(event: FocusEvent<HTMLButtonElement>) {
        if (!disabled) {
            setFocussed(false);
            if (onBlur) {
                onBlur(event);
            }
        }
    }

    function handleOver(event: MouseEvent<HTMLButtonElement>) {
        if (!disabled) {
            setHover(true);
            if (onMouseOver) {
                onMouseOver(event);
            }
        }
    }

    function handleOut(event: MouseEvent<HTMLButtonElement>) {
        if (!disabled) {
            setHover(false);
            if (onMouseOut) {
                onMouseOut(event);
            }
        }
    }

    // TODO: check if this works on touch devices or if we need to implement a
    // separate touch event.
    function handleDown(event: MouseEvent<HTMLButtonElement>) {
        if (!disabled && hover) {
            setActive(true);
            if (onMouseDown) {
                onMouseDown(event);
            }
            if ('undefined' !== typeof window) {
                window.addEventListener('mouseup', handleUp);
            }
        }
    }

    function handleUp() {
        setActive(false);
        if ('undefined' !== typeof window) {
            window.removeEventListener('mouseup', handleUp);
        }
    }

    function callOnDismiss() {
        if (onDismiss && !disabled) {
            onDismiss(createTagEvent() as TagDismissEvent);
        }
    }

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({ officialComponentName: 'tag' });
    }, []); // Only run once

    const theme = useTheme();
    const cssClasses = useStyleSheet(tagStyleSheet, {
        theme,
        disabled,
        dismissible,
        hover,
        active,
        emphasis,
        focussed,
        size,
        shape,
        selected,
        color,
        status,
        isToggle,
    });

    function getCloseButtonProps(): Pick<CloseButtonProps, 'emphasis' | 'surface'> {
        let closeEmphasis: CloseButtonProps['emphasis'] = emphasis;
        let closeSurface: CloseButtonProps['surface'] = color;
        if (!isToggle && emphasis === 'subtle' && selected) {
            // `selected`, "subtle" Tags display as "bold":
            closeEmphasis = 'bold';
        }
        if (isToggle) {
            if (selected) {
                if (emphasis === 'subtle' && color === 'gray') {
                    closeEmphasis = 'bold';
                }
            } else {
                if (emphasis !== 'minimal') {
                    closeSurface = 'gray';
                }
                closeEmphasis = emphasis === 'minimal' ? 'minimal' : 'subtle';
            }
        }
        return { emphasis: closeEmphasis, surface: closeSurface };
    }

    const { split: dataAttributes, remaining: buttonProps } = splitDataAttributes(otherProps);

    return (
        <span
            data-gs-uitk-component="tag"
            data-cy="gs-tag"
            data-size={size}
            id={id}
            style={style}
            title={title}
            {...dataAttributes}
            className={getTagRootClasses({ cssClasses, overrideClasses, className })}
        >
            <button
                {...tagButtonAttrs}
                {...buttonProps}
                data-cy="gs-tag__button"
                onClick={handleClick}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onMouseOver={handleOver}
                onMouseOut={handleOut}
                onMouseDown={handleDown}
                tabIndex={disabled ? -1 : tabIndex || 0}
                disabled={disabled}
                aria-pressed={selected}
                className={getTagButtonClasses({ cssClasses, overrideClasses })}
                aria-label={`Tag ${name ? `name ${name}` : ``} ${value ? `value ${value}` : ``} ${
                    status ? `status ${status}` : ``
                }`}
            >
                {children}
            </button>
            {dismissible && (
                <CloseButton
                    buttonAttrs={dismissButtonAttrs}
                    size="sm"
                    onClick={handleDismiss}
                    disabled={disabled}
                    {...getCloseButtonProps()}
                    classes={{
                        root: cx(cssClasses.closeRoot, overrideClasses?.closeRoot),
                        button: cx(cssClasses.closeButton, overrideClasses?.closeButton),
                    }}
                    aria-label="Remove"
                />
            )}
        </span>
    );
};

Tag.propTypes = {
    ...basePropTypes,
    onClick: PropTypes.func,
};
