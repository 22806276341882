const style = {
    color: 'inherit',
    fontSize: 38,
    fontFamily: 'Goldman Sans Thin',
    lineHeight: '28px',
    cursor: 'pointer',
};

export const Close = (props: any) => (
    <span className="custom-close" style={style} {...props}>
        &times;
    </span>
);
