import { FC } from 'react';

/**
 * This component is used to render a short amount of static dummy data.
 *
 * This component is useful to use when you need to populate a component with static dummy data.
 */
export const ContentShort: FC = () => (
    <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
        ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
        cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
    </p>
);

/**
 * This component is used to render a long amount of static dummy data.
 *
 * This component is useful to use when you need to populate a component with static dummy data.
 */
export const ContentLong: FC = () => (
    <div>
        <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur orci purus, mollis id
            tempor vel, imperdiet sit amet erat. Donec eget sem ornare dolor finibus suscipit quis
            sed dolor. Maecenas sed massa ut arcu mollis ullamcorper. Fusce nec nibh vitae metus
            placerat iaculis non ac velit. Fusce interdum vitae diam vel dictum. Ut at dui blandit,
            varius tortor elementum, pellentesque tellus. Phasellus et orci quis erat malesuada
            porta in in orci. Nullam interdum turpis metus, sed blandit quam lobortis nec. Mauris
            aliquam, dui vel venenatis fringilla, orci ipsum aliquet urna, non sodales nisi metus in
            libero. Ut ut interdum leo. Proin porta sodales dictum. Sed vitae condimentum enim,
            vitae sollicitudin orci. Maecenas mollis aliquet maximus. Interdum et malesuada fames ac
            ante ipsum primis in faucibus.
        </p>
        <p>
            Aenean id eros orci. Phasellus accumsan bibendum posuere. Quisque quis felis eu dolor
            hendrerit viverra. Maecenas ullamcorper erat ac massa laoreet mattis. Quisque faucibus,
            mi sit amet molestie convallis, mauris dolor pulvinar est, quis finibus enim tellus
            ultrices dui. Phasellus egestas mi augue, non rhoncus neque faucibus hendrerit. Etiam
            hendrerit arcu tortor, et maximus neque porttitor at. Etiam vestibulum purus ac tempor
            gravida. Suspendisse sodales viverra ornare. Aliquam ultrices, orci eu accumsan tempus,
            enim orci dapibus ante, quis luctus metus dolor gravida lacus.
        </p>
        <p>
            Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis
            egestas. In venenatis lacus in mi maximus, sed commodo eros condimentum. Nullam vel leo
            convallis, molestie massa ac, vestibulum nulla. Cras volutpat dapibus mauris, vitae
            facilisis nulla interdum ut. Pellentesque consequat, nunc nec vestibulum venenatis, elit
            lectus porta nulla, eget iaculis nisl augue id augue. Quisque lorem enim, maximus a urna
            non, aliquam elementum neque. Vestibulum at enim eu turpis maximus laoreet vitae eget
            neque.
        </p>
        <p>
            Nunc at tellus efficitur, posuere orci pretium, lacinia purus. Donec nec commodo ligula.
            Maecenas interdum fermentum laoreet. Donec dictum est nec leo semper, ac maximus arcu
            interdum. Aliquam euismod est quis tempor porttitor. Curabitur velit nibh, tincidunt
            eget posuere sit amet, faucibus vitae lorem. Aenean vel congue tellus, sed tempus diam.
            Sed quis condimentum augue. Aliquam vehicula dapibus lorem sed placerat. Integer
            fringilla sed turpis ut finibus. Nunc id turpis sed risus pellentesque rutrum quis eu
            odio. Etiam id ex vitae ante mattis eleifend et vitae ligula. Etiam quis sodales lacus.
            Mauris eget arcu nisi. Nulla malesuada consequat nibh. Quisque vulputate justo at magna
            ultricies fringilla.
        </p>
        <p>
            Suspendisse imperdiet diam eget dolor lacinia, in laoreet urna egestas. Nulla laoreet
            orci egestas, tristique lorem eget, malesuada nisl. Nullam fringilla mi eu ipsum
            lobortis, at dapibus purus cursus. Maecenas non congue justo. Morbi molestie eros erat,
            sed dignissim nisi aliquam sit amet. Nulla facilisi. Sed eu nisi vehicula, ultricies
            nulla sed, vehicula diam. Sed id purus tempus, venenatis risus ut, luctus sapien.
            Phasellus placerat aliquam egestas. Praesent viverra est non congue maximus. Nulla
            suscipit lacus vel lectus finibus, in cursus purus interdum. Vivamus vel orci vel orci
            consequat facilisis. Praesent eleifend, arcu et tempor convallis, libero enim volutpat
            justo, a fringilla quam dui vel ipsum. Mauris at suscipit purus. Maecenas dapibus,
            sapien sed mattis finibus, est tellus auctor sem, eu vestibulum orci tortor in turpis.
            Phasellus non ipsum nisl.
        </p>
        <p>
            Vivamus malesuada purus et rhoncus commodo. Cras tincidunt eleifend tempor. Praesent
            porttitor nunc eu condimentum tempus. Quisque a congue erat. Nunc et ante vel erat
            molestie mattis vitae eu nisl. Nunc at odio sit amet enim laoreet varius. Donec
            fermentum maximus erat, sed mollis lacus faucibus a. Curabitur facilisis bibendum nulla
            ac vestibulum. Nulla et sagittis metus. Nam tincidunt gravida erat nec lacinia. Ut nec
            condimentum dui.
        </p>
        <p>
            Praesent at purus bibendum, ultricies mauris et, consectetur orci. Nullam rhoncus mi
            dui, at volutpat ante aliquet a. Sed sit amet ipsum ac sapien egestas luctus at eu
            lectus. Duis ac quam non metus vestibulum pellentesque id id ex. Maecenas volutpat
            consectetur neque, id lacinia diam dictum eu. Pellentesque vel vestibulum ligula, quis
            lobortis velit. Pellentesque habitant morbi tristique senectus et netus et malesuada
            fames ac turpis egestas. Curabitur suscipit feugiat dui et aliquam. Nulla vitae sapien
            lectus. Donec pretium, enim in volutpat gravida, odio magna viverra metus, vitae rutrum
            justo dolor et leo. Aliquam consequat velit sapien, eu feugiat enim maximus sed.
            Curabitur mollis eget lacus nec malesuada. Aliquam non aliquam est.
        </p>
        <p>
            Etiam sit amet elit ac velit pellentesque pretium. Donec hendrerit ac purus et blandit.
            In facilisis sollicitudin nulla nec porta. Quisque eros risus, porta ut tristique a,
            tristique vitae massa. Sed non velit ante. Suspendisse ac blandit arcu, vel varius urna.
            Etiam eleifend neque nec enim facilisis sagittis. Sed a sollicitudin lorem. Nullam
            scelerisque erat nec gravida condimentum. Pellentesque nisi tellus, eleifend fringilla
            ornare vel, tempus sit amet ex. In hac habitasse platea dictumst. Nam sit amet nisl
            dignissim, posuere nisi eget, facilisis felis. Sed hendrerit lectus vitae nisi tempor,
            non pellentesque lorem vulputate.
        </p>
        <p>
            Duis vitae massa risus. Quisque mi velit, lacinia sit amet sem id, efficitur feugiat
            odio. Aliquam erat volutpat. Proin id rhoncus ligula. Etiam fermentum enim quam, at
            gravida nisi bibendum in. Proin sed facilisis lacus, sit amet dictum ex. Donec euismod
            id tortor id maximus.
        </p>
        <p>
            Aliquam et ex a dui eleifend ornare. Quisque varius metus ut viverra dapibus. Vivamus a
            sem eu sem vulputate scelerisque ac in risus. Integer dui velit, condimentum ac pretium
            a, venenatis ac odio. Ut dignissim luctus magna. Nulla nec orci metus. Donec nisi ex,
            ornare imperdiet dictum tempus, porta et est. Mauris sit amet ipsum felis. Nam
            condimentum elementum mi at mollis. Praesent felis justo, fringilla sit amet tellus nec,
            euismod pellentesque ipsum. Aliquam erat volutpat. Praesent congue ut lacus at
            consectetur. Pellentesque blandit iaculis magna vitae efficitur.
        </p>
        <p>
            Sed id ex nec metus fermentum scelerisque ac sit amet diam. Vestibulum maximus sapien
            vitae pulvinar porta. Aenean consequat tellus ac purus accumsan, sit amet tempor leo
            pellentesque. Pellentesque in libero velit. Sed blandit in quam eu vulputate. Phasellus
            vehicula urna a odio convallis varius. Suspendisse vulputate libero eget dolor faucibus
            eleifend et id lacus. Donec nec nisi urna. Donec fringilla congue est sit amet
            fringilla.
        </p>
        <p>
            Cras ut sem enim. Nullam porta odio non libero gravida, sed vestibulum augue pretium.
            Sed ut placerat justo. Mauris nulla libero, ultricies sed viverra mollis, dictum sed
            lectus. Vivamus placerat, nulla eget consequat fringilla, ante dui dignissim est, non
            rhoncus nisi lacus vitae ante. Phasellus aliquam purus vitae ornare fermentum. Donec nec
            pulvinar sapien, ut aliquet odio. Mauris nibh est, hendrerit nec imperdiet a, maximus
            sagittis arcu.
        </p>
        <p>
            Suspendisse potenti. Nunc luctus turpis augue, at egestas nisl mattis vitae. Etiam
            sodales justo orci, et facilisis eros eleifend condimentum. Aenean eu consequat leo, eu
            ultricies leo. Nulla vitae sem nunc. Donec nunc diam, scelerisque id rutrum non,
            accumsan et elit. Vestibulum ut nibh velit. In quis urna at tortor euismod tristique in
            nec sem. Sed in posuere lorem. Morbi consequat auctor nibh, sit amet pellentesque diam
            rhoncus et. Etiam semper ex ut sapien laoreet, in convallis leo fermentum. Duis mi
            sapien, laoreet a vulputate vitae, consectetur dignissim est. Donec in urna sit amet ex
            volutpat commodo pulvinar sit amet lorem. Mauris gravida turpis a velit sodales,
            pharetra mollis ipsum pulvinar.
        </p>
        <p>
            In hac habitasse platea dictumst. Aliquam nec elit at erat efficitur bibendum ac vel
            tortor. Nunc in placerat nisi. Pellentesque laoreet mi id convallis lacinia. Interdum et
            malesuada fames ac ante ipsum primis in faucibus. Nulla pretium gravida tellus, vitae
            malesuada arcu. Aliquam aliquam varius varius. Pellentesque ante nisi, pellentesque sed
            odio vitae, malesuada porta sem. Donec sit amet finibus eros.
        </p>
        <p>
            Nam laoreet sapien nisi, convallis convallis nulla varius sit amet. Suspendisse potenti.
            Duis non porta eros. Quisque feugiat, quam et fermentum finibus, neque sem luctus odio,
            at fringilla nisl quam vitae ante. Curabitur enim enim, aliquet eget eleifend non,
            suscipit at justo. Cras finibus risus vitae risus porttitor pretium quis vel dui. Mauris
            a feugiat lorem. Aliquam tellus ante, pretium quis pretium at, ornare ac magna. Praesent
            aliquet rhoncus varius.
        </p>
        <p>
            In hac habitasse platea dictumst. Morbi sit amet libero turpis. Sed facilisis efficitur
            urna. Nullam aliquet, purus ut semper interdum, nunc urna ullamcorper enim, at porttitor
            leo urna vel lectus. Nullam at maximus orci, a viverra erat. Sed vel rutrum diam, ac
            dictum odio. Etiam id ornare leo, vitae scelerisque quam. Quisque non consectetur purus.
            Nulla vel elit eget odio mattis ornare vel vel enim. Aenean luctus nec tortor vel
            molestie. Sed id lobortis urna. Integer lorem turpis, varius eu dictum nec, varius ac
            ante. Donec dignissim nibh sit amet lacus finibus egestas.
        </p>
        <p>
            Duis condimentum eu elit vel hendrerit. Etiam sit amet tellus odio. Pellentesque
            habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Sed
            felis ipsum, scelerisque ac dictum in, pellentesque sed nunc. Phasellus vitae eleifend
            orci. Proin blandit augue ac molestie vestibulum. Quisque tempus eros orci, in viverra
            dolor posuere id. Duis efficitur, ligula in gravida maximus, ante dolor vestibulum
            felis, ut hendrerit mauris lorem a ex.
        </p>
        <p>
            Aliquam diam quam, ultricies in massa a, consequat eleifend mauris. Fusce accumsan
            suscipit diam vel tempor. Aliquam mi lorem, suscipit non odio hendrerit, gravida
            hendrerit arcu. Vestibulum eu augue metus. Nunc velit dolor, pretium quis arcu ac,
            blandit convallis est. Aenean sit amet urna mattis, tincidunt magna non, tincidunt est.
            Nunc odio lacus, facilisis eu diam sed, dictum hendrerit massa. Quisque in nunc maximus,
            tristique tellus a, mattis metus.
        </p>
        <p>
            Nunc vitae massa blandit, dictum ipsum et, vehicula urna. Maecenas eget risus tincidunt,
            eleifend eros cursus, sagittis dolor. Sed et dictum velit. Nam eu ex tempor, cursus enim
            ultrices, porttitor mi. Nullam ultrices nulla nec tortor sagittis, non viverra lectus
            efficitur. Phasellus tincidunt, arcu non dapibus efficitur, est orci congue libero, id
            luctus dui diam sit amet mi. Ut gravida in erat ac convallis. Vivamus pellentesque ac
            lorem id rhoncus. Integer id neque et risus consectetur volutpat vel vel felis. Interdum
            et malesuada fames ac ante ipsum primis in faucibus. Sed mattis enim id est scelerisque
            convallis. Mauris ultrices, sapien vel posuere condimentum, enim risus pretium dui, quis
            vulputate erat ante scelerisque velit. Aliquam quis leo vitae lacus congue vehicula eget
            ut augue. Aliquam erat volutpat.
        </p>
        <p>
            Aliquam vel nulla vel neque commodo dictum sed pretium ex. In ut orci quis magna feugiat
            vehicula sit amet ac sem. Duis vehicula enim tincidunt, finibus sapien bibendum, finibus
            elit. Aliquam erat volutpat. Aliquam vel suscipit tellus. Nunc ac libero non tellus
            lobortis volutpat sed at metus. Duis mollis nibh in ipsum consequat lobortis. Praesent
            est eros, molestie eu magna in, varius egestas ante. Nam nec viverra felis. Ut in mauris
            at eros hendrerit aliquam et sed nisl. Phasellus tincidunt risus consectetur sapien
            ullamcorper, sit amet efficitur est efficitur. Integer fringilla, nunc ac laoreet
            egestas, massa justo imperdiet mauris, ut accumsan turpis eros vitae sem.
        </p>
    </div>
);
