// ** THIS FILE IS GENERATED. DO NOT MODIFY DIRECTLY **

const name = '@gs-ux-uitoolkit-react/collapse';

// NOTE: .trim() is needed on the following string because when the package
// is stamped with a version number (such as '1.2.3'), we actually pad the rest
// of the string with spaces so it ends up as: 
//     '1.2.3                   '.trim();
// Keeping the same number of characters in the source code after the replacement
// is so that we don't throw off the source maps which rely on line+column coordinates
const version = '0.0.0-UI-TOOLKIT-VERSION'.trim();

export const Version = { name, version };