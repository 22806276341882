import { PropsWithChildren, ButtonHTMLAttributes, WeakValidationMap } from 'react';
import PropTypes from 'prop-types';
import { ReactComponentProps } from '@gs-ux-uitoolkit-react/component';
import {
    TagCommonProps,
    TagToggleCommonProps,
    TagDismissEvent,
    TagClickEvent,
    TagSelectChangeEvent,
    tagColors,
    tagEmphases,
    tagSizes,
    tagShapes,
    tagStatuses,
} from '@gs-ux-uitoolkit-common/tag';

interface TagSharedProps {
    /**
     * Called when a user clicks the "close" button icon. Relevant only when
     * {@link #dismissible} is `true`.
     */
    onDismiss?: (event: TagDismissEvent) => void;
}

export interface TagProps
    extends PropsWithChildren<
        ReactComponentProps &
            TagCommonProps &
            TagSharedProps &
            Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'size' | 'name' | 'value' | 'onClick'>
    > {
    /**
     * Custom attributes for the main part of the tag that can be interacted with to select tag.
     *
     * This can be used to set analytics attributes.
     */
    tagButtonAttrs?: { [attrName: string]: string };

    /**
     * Custom HTML attributes for the dismiss button used to remove the tag.
     *
     * This can be used to set analytics attributes.
     */
    dismissButtonAttrs?: { [attrName: string]: string };

    /**
     * Called when a user clicks the component.
     */
    onClick?: (event: TagClickEvent) => void;
}

export interface TagToggleProps
    extends PropsWithChildren<
        ReactComponentProps &
            TagToggleCommonProps &
            TagSharedProps &
            Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'size' | 'name' | 'value' | 'onClick'>
    > {
    /**
     * Called when a user clicks the component to toggle the {@link #selected} state.
     */
    onSelectChange?: (event: TagSelectChangeEvent) => void;
}

export const basePropTypes: WeakValidationMap<TagCommonProps & TagSharedProps> = {
    color: PropTypes.oneOf(tagColors),
    dismissible: PropTypes.bool,
    emphasis: PropTypes.oneOf(tagEmphases),
    size: PropTypes.oneOf(tagSizes),
    shape: PropTypes.oneOf(tagShapes),
    selected: PropTypes.bool,
    status: PropTypes.oneOf(tagStatuses),
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onDismiss: PropTypes.func,
};
