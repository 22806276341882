import { FunctionComponent, useState } from 'react';
import PropTypes from 'prop-types';
import {
    TagSelectChangeEvent,
    TagClickEvent,
    defaultTagToggleProps,
} from '@gs-ux-uitoolkit-common/tag';
import { basePropTypes, TagToggleProps } from './tag-props';
import { Tag } from './tag';
import { TagContext } from './tag-context';

/**
 * TagToggle is a type of Tag that behaves as a switch or toggle.
 */
export const TagToggle: FunctionComponent<TagToggleProps> = props => {
    const {
        onSelectChange,
        defaultSelected = defaultTagToggleProps.defaultSelected,
        ...otherProps
    } = props;

    const isControlled = props.selected === true || props.selected === false;

    const [uncontrolledSelected, setUncontrolledSelected] = useState(defaultSelected || false);

    function handleClick(clickEvent: TagClickEvent) {
        let defaultPrevented = false;
        const event: TagSelectChangeEvent = {
            ...clickEvent,
            selected: !(isControlled ? props.selected : uncontrolledSelected),
            preventDefault: () => (defaultPrevented = true),
        };
        if (onSelectChange) {
            onSelectChange(event);
        }
        if (!isControlled && !defaultPrevented) {
            setUncontrolledSelected(event.selected);
        }
    }

    const selected = isControlled ? props.selected : uncontrolledSelected;

    return (
        <TagContext.Provider value={{ isToggle: true }}>
            <Tag
                data-cy="gs-tag-toggle"
                data-gs-uitk-component="tag-toggle"
                {...otherProps}
                selected={selected}
                onClick={handleClick}
            />
        </TagContext.Provider>
    );
};

TagToggle.propTypes = {
    ...basePropTypes,
    defaultSelected: PropTypes.bool,
    onSelectChange: PropTypes.func,
};
