import { CSSProperties, HTMLAttributes, FC, memo, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import {
    SliderProps as SliderCommonProps,
    sliderDefaultProps,
    sliderSizes,
} from '@gs-ux-uitoolkit-common/slider';
import { SliderBase } from './slider-base';

export interface SliderChangeEvent extends ChangeEvent<HTMLInputElement> {
    value: number;
}
export interface SliderProps
    extends SliderCommonProps<CSSProperties>,
        Omit<HTMLAttributes<HTMLElement>, 'defaultValue'> {
    onChange?: (event: SliderChangeEvent) => void;
}

/**
 * Sliders visualize and allow for the selection of a numeric value within a range.
 */
export const Slider: FC<SliderProps> = memo(props => {
    const {
        value,
        defaultValue,
        showValue = sliderDefaultProps.showValue,
        minValue = sliderDefaultProps.minValue,
        maxValue = sliderDefaultProps.maxValue,
        step = sliderDefaultProps.step,
        showLabels = sliderDefaultProps.showLabels,
        size = sliderDefaultProps.size,
        ...rest
    } = props;

    return (
        <SliderBase
            values={value !== undefined ? [value] : undefined}
            defaultValues={
                defaultValue !== undefined
                    ? [defaultValue]
                    : value === undefined
                      ? [(minValue + maxValue) / 2] // take midpoint, as default HTML input range behavior
                      : undefined
            }
            sliderType={'slider'}
            min={minValue}
            max={maxValue}
            showLabels={showLabels}
            showValues={showValue}
            size={size}
            step={step}
            {...rest}
        />
    );
});
Slider.displayName = 'Slider';

Slider.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
    step: PropTypes.number,
    showLabels: PropTypes.bool,
    size: PropTypes.oneOf(sliderSizes),
    value: PropTypes.number,
    classes: PropTypes.object,
    showValue: PropTypes.bool,
    defaultValue: PropTypes.number,
};
