import { FunctionComponent, useEffect } from 'react';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import {
    SkeletonItemProps,
    skeletonItemStyleSheet,
    getSkeletonItemRootClasses,
    defaultSkeletonItemProps,
} from '@gs-ux-uitoolkit-common/skeleton';
import { componentAnalytics } from './analytics-tracking';

/**
 * Skeleton item can be used to make a page 'appear' fast by adding placeholders
 * on the page while elements like avatar, badges, form components and buttons
 * are loading.
 */
export const SkeletonItem: FunctionComponent<SkeletonItemProps> = (props: SkeletonItemProps) => {
    const {
        shape = defaultSkeletonItemProps.shape,
        size = defaultSkeletonItemProps.size,
        width = defaultSkeletonItemProps.width,
        animation = defaultSkeletonItemProps.animation,
        disableAriaLabel = defaultSkeletonItemProps.disableAriaLabel,
        className,
        'aria-label': ariaLabel = defaultSkeletonItemProps['aria-label'],
        classes: overrideClasses,
        ...otherProps
    } = props;
    const theme = useTheme();
    const cssClasses = useStyleSheet(skeletonItemStyleSheet, { theme, ...props });

    const rootCx = getSkeletonItemRootClasses({ cssClasses, className, overrideClasses });

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({
            componentName: 'skeleton-item',
            officialComponentName: 'other',
        });
    }, []); // Only run once

    return (
        <div
            data-gs-uitk-component="skeleton-item"
            data-shape={shape}
            data-size={size}
            data-width={width}
            data-animation={animation}
            className={rootCx}
            aria-label={disableAriaLabel ? undefined : ariaLabel}
            {...otherProps}
        ></div>
    );
};
