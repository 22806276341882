import { FunctionComponent, useEffect } from 'react';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import {
    SkeletonTableProps,
    skeletonTableStyleSheet,
    getSkeletonTableRootClasses,
    getSkeletonTableHeaderClasses,
    getSkeletonTableCellClasses,
    getSkeletonTableGridClasses,
    defaultSkeletonTableProps,
} from '@gs-ux-uitoolkit-common/skeleton';
import { SkeletonText } from './skeleton-text';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { componentAnalytics } from './analytics-tracking';

/**
 * Skeleton table can be used to make a page 'appear' fast by adding placeholders on the page
 * for tables and grids while the data is being loaded.
 */
export const SkeletonTable: FunctionComponent<SkeletonTableProps> = (props: SkeletonTableProps) => {
    const {
        rows = defaultSkeletonTableProps.rows,
        columns = defaultSkeletonTableProps.columns,
        width = defaultSkeletonTableProps.width,
        animation = defaultSkeletonTableProps.animation,
        className,
        classes: overrideClasses,
        'aria-label': ariaLabel = defaultSkeletonTableProps['aria-label'],
        ...otherProps
    } = props;
    const theme = useTheme();
    const cssClasses = useStyleSheet(skeletonTableStyleSheet, { ...props, theme });

    // Get the CSS Stylesheets for skeleton table elements
    const rootCx = getSkeletonTableRootClasses({ cssClasses, className, overrideClasses });
    const gridCx = getSkeletonTableGridClasses({ cssClasses });
    const headerCx = getSkeletonTableHeaderClasses({ cssClasses });
    const cellCx = getSkeletonTableCellClasses({ cssClasses });

    const rowsMap = Array(rows).fill('');
    const columnsMap = Array(columns).fill('');

    useEffect(() => {
        //track component has rendered
        componentAnalytics.trackRender({
            componentName: 'skeleton-table',
            officialComponentName: 'other',
        });
    }, []); // Only run once

    return (
        <div
            data-gs-uitk-component="skeleton-table"
            data-rows={rows}
            data-columns={columns}
            data-width={width}
            data-animation={animation}
            className={rootCx}
            aria-busy="true"
            aria-live="off"
            aria-label={ariaLabel}
            {...otherProps}
        >
            <div className={headerCx}>
                {columnsMap.map((c, ci) => (
                    <SkeletonText
                        key={ci}
                        data-skeleton-header-cell={`cell-${c}${ci}`}
                        typography="body03"
                        width={`50%`}
                        animation={props.animation}
                        className={cellCx}
                        disableAriaLabel
                    />
                ))}
            </div>
            {rowsMap.map((r, ri) => (
                <div key={ri} className={gridCx}>
                    {columnsMap.map((c, ci) => (
                        <SkeletonText
                            key={ci}
                            data-skeleton-table-cell={`cell-${c}${ci}${r}${ri}`}
                            typography="body03"
                            width="80%"
                            animation={props.animation}
                            className={cellCx}
                            disableAriaLabel
                        />
                    ))}
                </div>
            ))}
        </div>
    );
};
