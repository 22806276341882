import { CSSProperties, HTMLAttributes, Fragment, useId, useState, useEffect, FC } from 'react';
import PropTypes from 'prop-types';
import { useStyleSheet } from '@gs-ux-uitoolkit-react/style';
import {
    sliderBaseDefaultProps,
    getSliderBaseRootClassNames,
    getSliderBaseInputClassNames,
    getSliderBaseTrackContainerClassNames,
    getSliderBaseTrackClassNames,
    getSliderBaseSelectedRangeClassNames,
    getSliderBaseLabelMinClassNames,
    getSliderBaseLabelMaxClassNames,
    getSliderBaseLabelValueClassNames,
    sliderBaseStyleSheet,
    SliderBaseSize,
    SliderBaseProps as SliderBaseCommonProps,
    SliderGlobalClassName,
} from '@gs-ux-uitoolkit-common/slider';
import { useTheme } from '@gs-ux-uitoolkit-react/theme';
import { SliderChangeEvent } from './slider';
import { SliderMultipleChangeEvent } from './slider-multiple';
import { extractAriaAttributes } from '@gs-ux-uitoolkit-react/shared';

export type SliderType = 'slider' | 'slider-multiple';

/**
 * Specific config for the slider single, with the slider type === 'slider' and the appropriate onChange
 */
interface SliderSingleConfig
    extends SliderBaseCommonProps<CSSProperties>,
        HTMLAttributes<HTMLElement> {
    onChange?: (event: SliderChangeEvent) => void;
    sliderType: 'slider';
}

/**
 * Specific config for the slider multiple, with the slider type === 'slider-multiple' and the appropriate onChange
 */
interface SliderMultipleConfig
    extends SliderBaseCommonProps<CSSProperties>,
        HTMLAttributes<HTMLElement> {
    onChange?: (event: SliderMultipleChangeEvent) => void;
    sliderType: 'slider-multiple';
}

type SliderBaseProps = SliderSingleConfig | SliderMultipleConfig;

/**
 * Multi-Point Slider to visualize and allow for the selection of numeric values within a range.
 */
export const SliderBase: FC<SliderBaseProps> = props => {
    const {
        min = sliderBaseDefaultProps.min,
        max = sliderBaseDefaultProps.max,
        step = sliderBaseDefaultProps.step,
        showValues = sliderBaseDefaultProps.showValues,
        disabled = sliderBaseDefaultProps.disabled,
        size = sliderBaseDefaultProps.size,
        showLabels = sliderBaseDefaultProps.showLabels,
        onChange,
        values,
        defaultValues,
        className,
        classes: overrideClasses,
        sliderType,
        ...attributes
    } = props;

    const [internalValues, setInternalValues] = useState(
        (defaultValues && defaultValues.sort((a, b) => a - b)) ||
            (values && values.sort((a, b) => a - b)) || [min, max]
    );

    const [activeSlider, setActiveSlider] = useState(0);

    useEffect(() => {
        if (values !== undefined) {
            const sortedValues = values.sort((a, b) => a - b);
            setInternalValues(sortedValues);
        }
    }, [JSON.stringify(values)]); // eslint-disable-line react-hooks/exhaustive-deps

    const theme = useTheme();
    const key = useId();

    const cssClasses = useStyleSheet(sliderBaseStyleSheet, {
        showValues,
        showLabels,
        values: internalValues,
        min,
        max,
        theme,
        disabled,
        size,
    });

    const globalClassName: SliderGlobalClassName = `gs-${sliderType}`;

    const sliderBaseRootClasses = getSliderBaseRootClassNames({
        cssClasses,
        className,
        overrideClasses,
        globalClassName,
    });
    const sliderBaseInputClasses = getSliderBaseInputClassNames({
        cssClasses,
        overrideClasses,
        globalClassName,
    });

    const sliderBaseLabelValueClasses = getSliderBaseLabelValueClassNames({
        cssClasses,
        overrideClasses,
        globalClassName,
    });

    const sliderBaseLabelMinClasses = getSliderBaseLabelMinClassNames({
        cssClasses,
        overrideClasses,
        globalClassName,
    });

    const sliderBaseLabelMaxClasses = getSliderBaseLabelMaxClassNames({
        cssClasses,
        overrideClasses,
        globalClassName,
    });

    const sliderBaseTrackContainerClasses = getSliderBaseTrackContainerClassNames({
        cssClasses,
    });

    const sliderBaseTrackClasses = getSliderBaseTrackClassNames({
        cssClasses,
        overrideClasses,
        globalClassName,
    });
    const sliderBaseRangeClasses = getSliderBaseSelectedRangeClassNames({
        cssClasses,
        overrideClasses,
        globalClassName,
    });

    function checkForSafeValueChange(index: number, eventValue: number) {
        // To prevent minimum being equal to or greater than maximum
        // we only set the value if less than the next greater value, else override the event value
        let value: number;
        if (internalValues[index] < eventValue) {
            // This is the case that the slider was dragged to a greater value
            // If it is the "topmost" slider, then we don't have to worry
            // Else check if the value is less than the next biggest slider
            value =
                index === internalValues.length - 1
                    ? eventValue
                    : Math.min(eventValue, internalValues[index + 1] - step);
        } else {
            // This is the case that the slider was dragged to a lower value
            // If it is the first ("bottom") slider, then we don't have to worry
            // Else check if the value is greater than the next smallest slider
            value =
                index === 0 ? eventValue : Math.max(eventValue, internalValues[index - 1] + step);
        }

        return value;
    }

    const ariaAttributes = extractAriaAttributes(attributes);

    return (
        <div
            data-gs-uitk-component={sliderType}
            data-size={size}
            className={sliderBaseRootClasses}
            data-cy={`gs-uitk-${sliderType}`}
            {...attributes}
        >
            {[...Array(internalValues.length).keys()].map(index => {
                return (
                    <Fragment key={`${key}-container-${index}`}>
                        <input
                            type="range"
                            disabled={disabled}
                            min={min}
                            max={max}
                            step={step}
                            value={internalValues[index]}
                            key={`${key}-input-${index}`}
                            data-cy={`gs-uitk-${sliderType}__input`}
                            className={sliderBaseInputClasses}
                            onChange={event => {
                                setActiveSlider(index);
                                const safeValueChange = checkForSafeValueChange(
                                    index,
                                    +event.target.value
                                );
                                event.target.value = safeValueChange.toString();

                                if (onChange) {
                                    if (sliderType === 'slider') {
                                        onChange({
                                            ...event,
                                            value: +event.target.value,
                                        });
                                    } else {
                                        onChange({
                                            ...event,
                                            values: internalValues.map((curr, i) =>
                                                i === index ? safeValueChange : curr
                                            ),
                                            activeIndex: index,
                                        });
                                    }
                                }

                                // only set the internal values if the component is uncontrolled
                                if (!values) {
                                    setInternalValues(prevState =>
                                        prevState.map((curr, i) =>
                                            i === index ? safeValueChange : curr
                                        )
                                    );
                                }
                            }}
                            style={{
                                zIndex:
                                    activeSlider === index
                                        ? internalValues.length + 3
                                        : `${index + 3}`,
                            }}
                            {...ariaAttributes}
                        />
                        {showValues && (
                            <div
                                className={sliderBaseLabelValueClasses}
                                key={`${key}-label-${index}`}
                                data-cy={`gs-uitk-${sliderType}__value`}
                                style={{
                                    left: calculateOffset(
                                        getPercent(internalValues[index], min, max),
                                        size
                                    ),
                                }}
                            >
                                {showValues === true
                                    ? internalValues[index]
                                    : showValues(internalValues[index])}
                            </div>
                        )}
                    </Fragment>
                );
            })}
            {showLabels && (
                <>
                    <div
                        className={sliderBaseLabelMinClasses}
                        style={{
                            left: calculateOffset(0, size),
                        }}
                        data-cy={`gs-uitk-${sliderType}__label-min`}
                        aria-hidden="true"
                    >
                        {showLabels === true ? min : showLabels(min)}
                    </div>
                    <div
                        className={sliderBaseLabelMaxClasses}
                        style={{
                            left: calculateOffset(100, size),
                        }}
                        data-cy={`gs-uitk-${sliderType}__label-max`}
                        aria-hidden="true"
                    >
                        {showLabels === true ? max : showLabels(max)}
                    </div>
                </>
            )}
            <div
                className={sliderBaseTrackContainerClasses}
                data-cy={`gs-uitk-${sliderType}__track-container`}
            >
                <div className={sliderBaseTrackClasses} data-cy={`gs-uitk-${sliderType}__track`} />
                <div className={sliderBaseRangeClasses} data-cy={`gs-uitk-${sliderType}__range`} />
            </div>
        </div>
    );
};

SliderBase.propTypes = {
    max: PropTypes.number,
    min: PropTypes.number,
    step: PropTypes.number,
    values: PropTypes.arrayOf(PropTypes.number.isRequired),
    defaultValues: PropTypes.arrayOf(PropTypes.number.isRequired),
    onChange: PropTypes.func,
    showValues: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
    showLabels: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    className: PropTypes.string,
    classes: PropTypes.object,
};

function getPercent(value: number, min: number, max: number) {
    return ((value - min) / (max - min)) * 100;
}

function getDimensionBySize(size: SliderBaseSize) {
    switch (size) {
        case 'sm':
            return 12;
        case 'lg':
            return 20;
        default:
            return 16;
    }
}

function calculateOffset(percent: number, size: SliderBaseSize) {
    // Formula: % of total slider - half the label size + half the thumb size - the percent * the thumb size
    return `calc(${percent}% - 15px + ${getDimensionBySize(size) / 2}px - ${
        (percent * getDimensionBySize(size)) / 100
    }px)`;
}
